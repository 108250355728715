import React, { Fragment } from 'react';
import Slider from 'react-slick';

import { MainButtons } from './MainButtons';
import {
  settingsForSNP2018N4S,
  settingsForSNP2013N2S,
  settingsForSNP2015N2S,
  settingsForSNP1020X4S,
  settingsForSNP1021X4S,
  settingsForSNP2012N2S,
  settingsForSNP1024N4S,
  settingsForSNP1011N1S,
  settingsForSNP1018N3S,
  settingsForSNP1022X4S,
  settingsForSNP1023X4S,
  settingsForSNP1019N3S,
} from './configSliders';

import './BodyOwndaysSnap.scss';

const availableStoreList = [
  'SNP2018N-4S-C1',
  'SNP2018N-4S-C2',
  'SNP2018N-4S-C3',
  'SNP1020X-4S-C1',
  'SNP1020X-4S-C2',
  'SNP1020X-4S-C3',
  'SNP1021X-4S-C1',
  'SNP1021X-4S-C2',
  'SNP1021X-4S-C3',
  'SNP1024N-4S-C1',
  'SNP1024N-4S-C2',
  'SNP1024N-4S-C3',
  'SNP1022X-4S-C1',
  'SNP1022X-4S-C2',
  'SNP1022X-4S-C3',
  'SNP1023X-4S-C1',
  'SNP1023X-4S-C2',
  'SNP1023X-4S-C3',
];

const getButtonText = sku =>
  availableStoreList.includes(sku) ? 'Available In-Store Only' : 'View Product';
const getLink = sku =>
  availableStoreList.includes(sku)
    ? '/stores/sg'
    : `/sg/en/owndays-snap-${sku.toLowerCase()}-eyeglasses.html `;

const Slide = ({ bannerImg, productImg, sku }) => {
  const buttonText = getButtonText(sku);
  const link = getLink(sku);

  return (
    <li>
      <div className="lineup-owndays-snap__products__list-image-products__slider-container__tag-news">
        {bannerImg && (
          <picture>
            <source media="(min-width:768px)" srcSet={`${bannerImg}-pc.webp`} type="image/webp" />
            <img alt={sku} className="lazyload" data-sizes="auto" src={`${bannerImg}-sp.webp`} />
          </picture>
        )}
      </div>
      <picture>
        <source srcSet={`${productImg}.webp`} type="image/webp" />
        <img alt={sku} className="lazyload" data-sizes="auto" src={`${productImg}.webp`} />
      </picture>

      <a
        className="base-owndays-snap__btn base-owndays-snap--blue"
        href={link}
        rel="noreferrer"
        style={{ marginTop: '60px', zIndex: 1 }}
        target="_blank"
      >
        <div>
          <span className="base-owndays-snap__btn__label-down">{buttonText}</span>
          <span className="base-owndays-snap__btn__label-down">{buttonText}</span>
        </div>
      </a>
    </li>
  );
};

const SlideHorizontal = ({ bannerImg, productImg, sku }) => {
  const buttonText = getButtonText(sku);
  const link = getLink(sku);

  return (
    <li className="slide-horizontal-od-snap__layout">
      <div className="slide-horizontal-od-snap__image-layout">
        {bannerImg && (
          <picture>
            <source media="(min-width:768px)" srcSet={`${bannerImg}-pc.webp`} type="image/webp" />
            <img
              alt={sku}
              className="lazyload slide-horizontal-od-snap__tag-new"
              data-sizes="auto"
              src={`${bannerImg}-sp.webp`}
            />
          </picture>
        )}
        <picture>
          <source srcSet={`${productImg}.webp`} type="image/webp" />
          <img
            alt={sku}
            className="lazyload slide-horizontal-od-snap__main-image"
            data-sizes="auto"
            src={`${productImg}.webp`}
          />
        </picture>
      </div>

      <a
        className="base-owndays-snap__btn base-owndays-snap--blue slide-horizontal-od-snap__button"
        href={link}
        rel="noreferrer"
        target="_blank"
      >
        <div>
          <span className="base-owndays-snap__btn__label-down">{buttonText}</span>
          <span className="base-owndays-snap__btn__label-down">{buttonText}</span>
        </div>
      </a>
    </li>
  );
};

export const BodyOwndaysSnap = ({ baseImgUrl }) => {
  return (
    <Fragment>
      <section className="lineup-owndays-snap">
        <div className="base-owndays-snap__container">
          <h2
            className="base-owndays-snap__title base-owndays-snap__action-left"
            data-aos="no-animation"
            data-aos-duration="200"
          >
            <span className="base-owndays-snap__title--blue">L</span>
            <span>INE</span>
            <span className="base-owndays-snap__title--green">U</span>
            <span>P</span>
          </h2>

          <div className="lineup-owndays-snap-section">
            <div className="lineup-owndays-snap__banner-top">
              <picture className="base-owndays-snap__action-right" data-aos="no-animation">
                <source
                  media="(min-width:768px)"
                  srcSet={`${baseImgUrl}/lineup-banner-pc.webp`}
                  type="image/webp"
                />
                <img
                  alt="SNAP STANDARD"
                  className="lazyload"
                  data-sizes="auto"
                  src={`${baseImgUrl}/lineup-banner-sp.webp`}
                />
              </picture>
              <div className="sky-right">
                <picture
                  className="base-owndays-snap__action-right"
                  data-aos="no-animation"
                  data-aos-duration="200"
                >
                  <source
                    media="(min-width:768px)"
                    srcSet={`${baseImgUrl}/sky-right-pc.webp`}
                    type="image/webp"
                  />
                  <img
                    alt="SNAP STANDARD"
                    className="lazyload"
                    data-sizes="auto"
                    src={`${baseImgUrl}/sky-right-sp.webp`}
                  />
                </picture>
              </div>
              <div
                className="lineup-owndays-snap__banner-top__tabs-text base-owndays-snap__action-left"
                data-aos="no-animation"
                data-aos-duration="200"
              >
                <div className="lineup-owndays-snap__banner-top__tabs-text__text-detail">
                  <p>Meet the latest OWNDAYS SNAP models!</p>
                </div>
                <div className="lineup-owndays-snap__banner-top__tabs-text__text-price">
                  <p>
                    <span>S$218 per pair</span>
                  </p>
                </div>
              </div>
            </div>

            <div className="lineup-owndays-snap__detail">
              <h3 className="base-owndays-snap__title">
                <span className="base-owndays-snap__title--blue">S</span>
                <span>TAN</span>
                <span className="base-owndays-snap__title--green">D</span>
                <span>ARD</span>
              </h3>
              <p>
                Coming in versatile designs that work perfectly with any face shape or outfit
                coordination,
                <br />
                these are essential pieces that everyone should incorporate into their everyday
                look.
              </p>
            </div>

            <div className="lineup-owndays-snap__products">
              <div className="lineup-owndays-snap__products__list-image-products">
                <div className="lineup-owndays-snap__products__list-image-products__slider-group">
                  <Slider
                    {...settingsForSNP2018N4S}
                    className="lineup-owndays-snap__products__list-image-products__slider-container"
                  >
                    <Slide
                      bannerImg={`${baseImgUrl}/new`}
                      productImg={`${baseImgUrl}/SNP2018N-4S-C1`}
                      sku="SNP2018N-4S-C1"
                    />
                    <Slide
                      bannerImg={`${baseImgUrl}/new`}
                      productImg={`${baseImgUrl}/SNP2018N-4S-C2`}
                      sku="SNP2018N-4S-C2"
                    />
                    <Slide
                      bannerImg={`${baseImgUrl}/new`}
                      productImg={`${baseImgUrl}/SNP2018N-4S-C3`}
                      sku="SNP2018N-4S-C3"
                    />
                  </Slider>

                  <Slider
                    {...settingsForSNP2013N2S}
                    className="lineup-owndays-snap__products__list-image-products__slider-container"
                  >
                    <Slide productImg={`${baseImgUrl}/SNP2013N-2S-C1`} sku="SNP2013N-2S-C1" />
                    <Slide productImg={`${baseImgUrl}/SNP2013N-2S-C2`} sku="SNP2013N-2S-C2" />
                    <Slide productImg={`${baseImgUrl}/SNP2013N-2S-C3`} sku="SNP2013N-2S-C3" />
                  </Slider>

                  <Slider
                    {...settingsForSNP2015N2S}
                    className="lineup-owndays-snap__products__list-image-products__slider-container"
                  >
                    <Slide productImg={`${baseImgUrl}/SNP2015N-2S-C1`} sku="SNP2015N-2S-C1" />
                    <Slide productImg={`${baseImgUrl}/SNP2015N-2S-C2`} sku="SNP2015N-2S-C2" />
                    <Slide productImg={`${baseImgUrl}/SNP2015N-2S-C3`} sku="SNP2015N-2S-C3" />
                  </Slider>
                </div>
              </div>
            </div>
          </div>

          <div className="lineup-owndays-snap-section">
            <div className="lineup-owndays-snap__banner">
              <picture
                className="base-owndays-snap__action-left"
                data-aos="no-animation"
                data-aos-duration="200"
              >
                <source
                  media="(min-width:768px)"
                  srcSet={`${baseImgUrl}/lineup-banner02-pc.webp`}
                  type="image/webp"
                />
                <img
                  alt="SNAP BUSINESS"
                  className="lazyload"
                  data-sizes="auto"
                  src={`${baseImgUrl}/lineup-banner02-sp.webp`}
                />
              </picture>
            </div>

            <div className="lineup-owndays-snap__section-right">
              <div className="lineup-owndays-snap__detail">
                <h3 className="base-owndays-snap__title">
                  <span className="base-owndays-snap__title--blue">B</span>
                  <span>USI</span>
                  <span className="base-owndays-snap__title--green">N</span>
                  <span>ESS</span>
                </h3>
                <p>
                  The sleek silhouette and chic colours make these eyewear the perfect choice for
                  formal occasions and business settings,
                  <br />
                  especially when you are all for leaving a lasting impression.
                </p>
              </div>

              <div className="lineup-owndays-snap__products">
                <div className="lineup-owndays-snap__products__list-image-products">
                  <div className="lineup-owndays-snap__products__list-image-products__slider-group">
                    <Slider {...settingsForSNP1020X4S}>
                      <SlideHorizontal
                        bannerImg={`${baseImgUrl}/new`}
                        productImg={`${baseImgUrl}/SNP1020X-4S-C1`}
                        sku="SNP1020X-4S-C1"
                      />
                      <SlideHorizontal
                        bannerImg={`${baseImgUrl}/new`}
                        productImg={`${baseImgUrl}/SNP1020X-4S-C2`}
                        sku="SNP1020X-4S-C2"
                      />
                      <SlideHorizontal
                        bannerImg={`${baseImgUrl}/new`}
                        productImg={`${baseImgUrl}/SNP1020X-4S-C3`}
                        sku="SNP1020X-4S-C3"
                      />
                    </Slider>

                    <Slider {...settingsForSNP1021X4S}>
                      <SlideHorizontal
                        bannerImg={`${baseImgUrl}/new`}
                        productImg={`${baseImgUrl}/SNP1021X-4S-C1`}
                        sku="SNP1021X-4S-C1"
                      />
                      <SlideHorizontal
                        bannerImg={`${baseImgUrl}/new`}
                        productImg={`${baseImgUrl}/SNP1021X-4S-C2`}
                        sku="SNP1021X-4S-C2"
                      />
                      <SlideHorizontal
                        bannerImg={`${baseImgUrl}/new`}
                        productImg={`${baseImgUrl}/SNP1021X-4S-C3`}
                        sku="SNP1021X-4S-C3"
                      />
                    </Slider>

                    <Slider {...settingsForSNP2012N2S}>
                      <SlideHorizontal
                        productImg={`${baseImgUrl}/SNP2012N-2S-C1`}
                        sku="SNP2012N-2S-C1"
                      />
                      <SlideHorizontal
                        productImg={`${baseImgUrl}/SNP2012N-2S-C2`}
                        sku="SNP2012N-2S-C2"
                      />
                      <SlideHorizontal
                        productImg={`${baseImgUrl}/SNP2012N-2S-C3`}
                        sku="SNP2012N-2S-C3"
                      />
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="lineup-owndays-snap-section">
            <div className="lineup-owndays-snap__banner-secondary">
              <picture
                className="base-owndays-snap__action-left"
                data-aos="no-animation"
                data-aos-duration="200"
              >
                <source
                  media="(min-width:768px)"
                  srcSet={`${baseImgUrl}/lineup-banner03-pc.webp`}
                  type="image/webp"
                />
                <img
                  alt="SNAP MODERN"
                  className="lazyload"
                  data-sizes="auto"
                  src={`${baseImgUrl}/lineup-banner03-sp.webp`}
                />
              </picture>

              <div
                className="sky-bottom-right base-owndays-snap__action-right"
                data-aos="no-animation"
                data-aos-duration="200"
              >
                <img
                  alt="SNAP MODERN"
                  className="lazyload"
                  data-sizes="auto"
                  src={`${baseImgUrl}/sky-bottom-right.svg`}
                />
              </div>
            </div>

            <div className="lineup-owndays-snap__detail">
              <h3 className="base-owndays-snap__title">
                <span className="base-owndays-snap__title--blue">M</span>
                <span>O</span>
                <span className="base-owndays-snap__title--green">D</span>
                <span>ERN</span>
              </h3>
              <p>
                Designed to help you channel the fashionista in you,
                <br />
                these eyewear are stylish and come in an assortment of colour options so you can
                express your unique personality freely.
              </p>
            </div>

            <div className="lineup-owndays-snap__products">
              <div className="lineup-owndays-snap__products__list-image-products">
                <div className="lineup-owndays-snap__products__list-image-products__slider-group">
                  <Slider
                    {...settingsForSNP1024N4S}
                    className="lineup-owndays-snap__products__list-image-products__slider-container"
                  >
                    <Slide
                      bannerImg={`${baseImgUrl}/new`}
                      productImg={`${baseImgUrl}/SNP1024N-4S-C1`}
                      sku="SNP1024N-4S-C1"
                    />
                    <Slide
                      bannerImg={`${baseImgUrl}/new`}
                      productImg={`${baseImgUrl}/SNP2018N-4S-C2`}
                      sku="SNP1024N-4S-C2"
                    />
                    <Slide
                      bannerImg={`${baseImgUrl}/new`}
                      productImg={`${baseImgUrl}/SNP1024N-4S-C3`}
                      sku="SNP1024N-4S-C3"
                    />
                  </Slider>

                  <Slider
                    {...settingsForSNP1011N1S}
                    className="lineup-owndays-snap__products__list-image-products__slider-container"
                  >
                    <Slide productImg={`${baseImgUrl}/SNP1011N-1S-C1`} sku="SNP1011N-1S" />
                    <Slide productImg={`${baseImgUrl}/SNP1011N-1S-C2`} sku="SNP1011N-1S-C2" />
                    <Slide productImg={`${baseImgUrl}/SNP1011N-1S-C3`} sku="SNP1011N-1S-C3" />
                  </Slider>

                  <Slider
                    {...settingsForSNP1018N3S}
                    className="lineup-owndays-snap__products__list-image-products__slider-container"
                  >
                    <Slide productImg={`${baseImgUrl}/SNP1018N-3S-C1`} sku="SNP1018N-3S-C1" />
                    <Slide productImg={`${baseImgUrl}/SNP1018N-3S-C2`} sku="SNP1018N-3S-C2" />
                    <Slide productImg={`${baseImgUrl}/SNP1018N-3S-C3`} sku="SNP1018N-3S-C3" />
                  </Slider>
                </div>
              </div>
            </div>
          </div>

          <div className="lineup-owndays-snap-section row-reverse-pc">
            <div className="lineup-owndays-snap__banner">
              <picture
                className="base-owndays-snap__action-right"
                data-aos="no-animation"
                data-aos-duration="200"
                data-aos-offset="500"
              >
                <source
                  media="(min-width:768px)"
                  srcSet={`${baseImgUrl}/lineup-banner04-pc.webp`}
                  type="image/webp"
                />
                <img
                  alt="SNAP CLASSIC"
                  className="lazyload"
                  data-sizes="auto"
                  src={`${baseImgUrl}/lineup-banner04-sp.webp`}
                />
              </picture>
            </div>

            <div className="lineup-owndays-snap__section-right">
              <div className="lineup-owndays-snap__detail">
                <h3 className="base-owndays-snap__title">
                  <span className="base-owndays-snap__title--blue">C</span>
                  <span>LAS</span>
                  <span className="base-owndays-snap__title--green">S</span>
                  <span>IC</span>
                </h3>
                <p>
                  Infusing a touch of contemporary aesthetics into timeless,
                  <br /> classic designs to give eyewear that will stand the test of time.
                </p>
              </div>
              <div className="lineup-owndays-snap__products">
                <div className="lineup-owndays-snap__products__list-image-products">
                  <div className="lineup-owndays-snap__products__list-image-products__slider-group">
                    <Slider {...settingsForSNP1022X4S}>
                      <SlideHorizontal
                        bannerImg={`${baseImgUrl}/new`}
                        productImg={`${baseImgUrl}/SNP1022X-4S-C1`}
                        sku="SNP1022X-4S-C1"
                      />
                      <SlideHorizontal
                        bannerImg={`${baseImgUrl}/new`}
                        productImg={`${baseImgUrl}/SNP1022X-4S-C2`}
                        sku="SNP1022X-4S-C2"
                      />
                      <SlideHorizontal
                        bannerImg={`${baseImgUrl}/new`}
                        productImg={`${baseImgUrl}/SNP1022X-4S-C3`}
                        sku="SNP1022X-4S-C3"
                      />
                    </Slider>

                    <Slider {...settingsForSNP1023X4S}>
                      <SlideHorizontal
                        bannerImg={`${baseImgUrl}/new`}
                        productImg={`${baseImgUrl}/SNP1023X-4S-C1`}
                        sku="SNP1023X-4S-C1"
                      />
                      <SlideHorizontal
                        bannerImg={`${baseImgUrl}/new`}
                        productImg={`${baseImgUrl}/SNP1023X-4S-C2`}
                        sku="SNP1023X-4S-C2"
                      />
                      <SlideHorizontal
                        bannerImg={`${baseImgUrl}/new`}
                        productImg={`${baseImgUrl}/SNP1023X-4S-C3`}
                        sku="SNP1023X-4S-C3"
                      />
                    </Slider>

                    <Slider {...settingsForSNP1019N3S}>
                      <SlideHorizontal
                        productImg={`${baseImgUrl}/SNP1019N-3S-C1`}
                        sku="SNP1019N-3S-C1"
                      />
                      <SlideHorizontal
                        productImg={`${baseImgUrl}/SNP1019N-3S-C2`}
                        sku="SNP1019N-3S-C2"
                      />
                      <SlideHorizontal
                        productImg={`${baseImgUrl}/SNP1019N-3S-C3`}
                        sku="SNP1019N-3S-C3"
                      />
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <MainButtons />
        </div>
      </section>
    </Fragment>
  );
};
