import React from 'react';

import { DotPalette } from './DotPalette';

const baseImgUrl = 'https://static.lenskart.com/media/owndays/img/owndays-plus';

const defaultSetting = {
  slidesToShow: 1,
  slidesToScroll: 2,
  arrows: false,
  dots: true,
  fade: true,
  draggable: false,
  infinite: true,
  swipe: false,
  touchMove: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        draggable: true,
        infinite: true,
        swipe: true,
        touchMove: true,
      },
    },
  ],
};

export const featureSetting = {
  slidesToShow: 1,
  centerMode: true,
  infinite: true,
  swipe: true,
  touchMove: true,
  centerPadding: '13.6%',
  dots: false,
  draggable: false,
  arrows: false,
};

const CustomDots = props => {
  const backgroundColors = {
    default: ['#000', `${baseImgUrl}/cl-gray.webp`, `${baseImgUrl}/cl-brown-demi.webp`, '#f1ddc4'],
    'OR2083L-4S': ['#000', `${baseImgUrl}/cl-brown-demi.webp`, '#f1ddc4', '#fee4e4'],
    'OR2061L-2S': ['#000', '#f7f5f4', '#253b5e', '#f1ddc4'],
    'OR2080L-4S': ['#000', '#f7f5f4', '#f1ddc4', '#def9eb'],
    'OR2084L-4S': ['#000', '#f7f5f4', `${baseImgUrl}/cl-brown-demi.webp`, '#f1ddc4'],
    'OR2065L-2S': [`${baseImgUrl}/cl-brown-demi.webp`, '#f1ddc4', '#540922', '#424021'],
    'OR2082L-4S': [
      '#000',
      '#253b5e',
      `${baseImgUrl}/cl-brown-demi.webp`,
      `${baseImgUrl}/cl-clear.webp`,
    ],
    'OR2064L-2S': ['#000', `${baseImgUrl}/cl-brown-demi.webp`, '#855928', '#e1d4b3'],
    'OR2063L-2S': ['#000', `${baseImgUrl}/cl-brown-demi.webp`, '#855928', '#f7f5f4'],
  };

  return <DotPalette {...props} backgroundColors={backgroundColors} />;
};

const initialSlides = {
  'OR2081L-4S': 0,
  'OR2083L-4S': 0,
  'OR2061L-2S': 0,
  'OR2080L-4S': 0,
  'OR2084L-4S': 0,
  'OR2065L-2S': 0,
  'OR2082L-4S': 0,
  'OR2064L-2S': 0,
  'OR2063L-2S': 0,
};

const createSettings = sku => {
  const initialSlide = initialSlides[sku];

  return {
    ...defaultSetting,
    initialSlide,
    beforeChange: (oldIndex, newIndex) => {
      initialSlides[sku] = newIndex;
    },
    appendDots: dots => <CustomDots dots={dots} initialSlide={initialSlides[sku]} sku={sku} />,
  };
};

// Generate settings for each SKU
export const settingsForOR2081L4S = createSettings('OR2081L-4S');
export const settingsForOR2083L4S = createSettings('OR2083L-4S');
export const settingsForOR2061L2S = createSettings('OR2061L-2S');
export const settingsForOR2080L4S = createSettings('OR2080L-4S');
export const settingsForOR2084L4S = createSettings('OR2084L-4S');
export const settingsForOR2065L2S = createSettings('OR2065L-2S');
export const settingsForOR2082L4S = createSettings('OR2082L-4S');
export const settingsForOR2064L2S = createSettings('OR2064L-2S');
export const settingsForOR2063L2S = createSettings('OR2063L-2S');
