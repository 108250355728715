import { getProductPrice } from '../../src/own-days/helper.js';

// This helper will get userInfo from localStorage
const getUserInfoFromLocalStorage = () => {
  const user = JSON.parse(localStorage.getItem('user-info'));

  if (user) {
    return {
      userName: user?.name ?? '',
      userEmail: user?.email ?? '',
      userPhone: user?.phone ?? '',
      userGender: user?.gender ?? '',
    };
  }

  return {
    user: 'guest',
  };
};

const transformUserInfo = data => {
  const userInfo = data.result ?? data;

  if (!userInfo) return userInfo;

  return {
    login: userInfo.login,
    tierLabel: userInfo.tierLabel ?? userInfo.tier_label,
  };
};

const transformCartData = data => {
  const cartData = data.result ?? data;

  if (!cartData) return cartData;

  return {
    id: cartData.id,
  };
};

const transformClevertapEventData = params => ({
  ...params,
  ...(params.userInfo ? { userInfo: transformUserInfo(params.userInfo) } : {}),
  ...(params.cartData ? { cartData: transformCartData(params.cartData) } : {}),
});

const getPayloadCommon = ({ userInfo }) => ({
  isLoggedLogin: !!userInfo?.login,
  userInfo: userInfo || null,
});

const getPayloadAddedToCart = ({ productData, packageId, coatingId, prescriptionType }) => ({
  productId: productData?.id,
  category: productData?.classification,
  price: getProductPrice(productData),
  package: packageId,
  coatingId,
  prescriptionType,
  deviceType: /Mobi/.test(navigator.userAgent) ? 'Mobile' : 'Desktop',
  ...getUserInfoFromLocalStorage(),
});

const getPayloadPageViewed = ({ pageName, pageUrl }) => ({
  pageName,
  pageUrl,
  deviceType: /Mobi/.test(navigator.userAgent) ? 'Mobile' : 'Desktop',
  ...getUserInfoFromLocalStorage(),
});

const getPayloadProductViewed = ({
  productID,
  productName,
  category,
  price,
  currency,
  brand,
  variant,
  productURL,
  imageURL,
}) => ({
  productID,
  productName,
  category,
  price,
  currency,
  brand,
  variant,
  productURL,
  imageURL,
  deviceType: /Mobi/.test(navigator.userAgent) ? 'Mobile' : 'Desktop',
  ...getUserInfoFromLocalStorage(),
});

const getPayloadProceedToCheckoutCart = ({ userInfo, cartData }) => ({
  ...getPayloadCommon({ userInfo }),
  cartId: cartData?.id,
});

const getPayloadProceedToCheckoutAddress = ({ userInfo, cartData }) => ({
  ...getPayloadCommon({ userInfo }),
  cartId: cartData?.id,
});

const getPayloadProceedToPayment = ({ userInfo, cartData }) => ({
  ...getPayloadCommon({ userInfo }),
  cartId: cartData?.id,
});

const payloadGetters = {
  'Page Viewed': getPayloadPageViewed,
  'Product Viewed': getPayloadProductViewed,
  'Added to Cart': getPayloadAddedToCart,
  'Proceed to checkout - cart': getPayloadProceedToCheckoutCart,
  'Proceed to checkout - address': getPayloadProceedToCheckoutAddress,
  'Proceed to payment': getPayloadProceedToPayment,
};

let PENDING_EVENTS = [];
let clevertapInterval;

export const odPushClevertapEvent = (event, data = {}, overrides = {}, type = 'event') => {
  console.log('odPushClevertapEvent event data overrides type', event, data, overrides, type);

  const transformedData = transformClevertapEventData(data);

  console.log('odPushClevertapEvent transformedData', transformedData);

  console.log('odPushClevertapEvent payload', payloadGetters[event]?.(transformedData) || {});

  const payload = [
    event,
    {
      ...(payloadGetters[event]?.(transformedData) || {}),
      ...overrides,
    },
  ];

  PENDING_EVENTS.push({ type, payload });

  console.log('FINAL PENDING EVENTS ===>', PENDING_EVENTS);

  if (window.clevertap) {
    if (clevertapInterval) {
      clearInterval(clevertapInterval);

      clevertapInterval = null;
    }

    console.log('debugging clevertap helper ==> 1', PENDING_EVENTS);
    PENDING_EVENTS.forEach(({ type, payload }) => window.clevertap[type].push(...payload));

    PENDING_EVENTS = [];
  } else if (!clevertapInterval) {
    clevertapInterval = setInterval(() => {
      if (window.clevertap) {
        clearInterval(clevertapInterval);

        clevertapInterval = null;

        console.log('debugging clevertap helper ==> 2', PENDING_EVENTS);
        PENDING_EVENTS.forEach(({ type, payload }) => window.clevertap[type].push(...payload));

        PENDING_EVENTS = [];
      }
    }, 10);
  } else {
    console.log('NO_CLEVERTAP, sent to interval');
  }
};
