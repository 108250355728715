import React, { Fragment } from 'react';

const baseImgUrl = 'https://static.lenskart.com/media/owndays/img/ortho-k-lens';

export const HOW_WORKS = [
  {
    alt: 'Myopic eye (short-sighted)',
    imgSrc: `${baseImgUrl}/myopic-eye.svg`,
    imgStyle: { maxWidth: '163px' },
    title: 'Myopic eye',
    subtitle: 'short-sighted',
  },
  {
    alt: 'With Ortho-K lens fitted',
    imgSrc: `${baseImgUrl}/with-ortho-k-lens-fitted.svg`,
    imgStyle: { maxWidth: '130px' },
    title: 'With Ortho-K lens',
    subtitle: 'fitted',
  },
  {
    alt: 'During overnight wear',
    imgSrc: `${baseImgUrl}/during-overnight-wear.svg`,
    imgStyle: { maxWidth: '130px' },
    title: 'During',
    subtitle: 'overnight wear',
  },
  {
    alt: 'Ortho-K lens removed',
    imgSrc: `${baseImgUrl}/ortho-k-lens-removed.svg`,
    imgStyle: { maxWidth: '165px' },
    title: 'Ortho-K lens',
    subtitle: 'removed',
  },
];

export const BENEFITS = [
  {
    alt: 'Control myopia progression',
    src: 'icon-control-myopia-progression.svg',
    title: (
      <Fragment>
        Control
        <br />
        myopia progression
      </Fragment>
    ),
    description:
      'Ortho-K offers an effective way to slow down the progression of myopia in children by preventing the elongation of the eyeball which leads to myopia.',
  },
  {
    alt: 'Spectacle-free vision all day long',
    src: 'icon-spectacle-free.svg',
    title: (
      <Fragment>
        Spectacle-free vision
        <br />
        all day long
      </Fragment>
    ),
    description:
      'With consistent wear, you will be able to see clearly without having to wear spectacles during the day.',
  },
  {
    alt: 'Safe and reversible process',
    src: 'icon-safe-and-reversible-process.svg',
    title: (
      <Fragment>
        Safe
        <br />
        and reversible process
      </Fragment>
    ),
    description:
      'Ortho-K is a surgery-free treatment that is reversible and can be stopped at any period of time. The cornea will return to its original shape and refractive power will return gradually when you cease wearing the lenses.',
  },
  {
    alt: 'An alternative to LASIK or refractive surgery',
    src: 'icon-an-alternative-to-lasik.svg',
    title: (
      <Fragment>
        An alternative to LASIK
        <br />
        or refractive surgery
      </Fragment>
    ),
    description:
      'Ortho-K is currently considered one of the most preferred options for myopia management in children since surgical procedures are not recommended until one’s vision becomes stable.',
  },
];

export const RECOMMENDED_LIST = [
  {
    name: 'Children and people who are not good candidates for refractive surgery',
  },
  {
    name: 'People who would like to achieve spectacle-free vision without wearing soft contact lenses',
  },
  {
    name: 'People who perform recreational and sports activities in which periods without visual correction are beneficial',
  },
  {
    name: 'People whose vocation requires unaided visual acuity for certain periods (e.g. the police, firemen, military personnel) or occupations in which refractive surgery may be a cause for exclusion (e.g. deep-sea divers, pilots)',
  },
];

export const TREATMENT_STEPS = [
  {
    step: 'Consultation & Assessment',
    answer: `Our licensed optometrist will provide a pre-assessment* to find out the customer’s suitability and their expectation on the outcome of Ortho-K.`,
    note: `Consultation fee of $50 applies for pre-assessment and will be waived for purchase of Ortho-K lenses made within 1 month of consultation.`,
  },
  {
    step: 'Collection of Lenses',
    answer: `Lenses will be ready for collection in approximately 2 weeks after purchase. During collection, the optometrist will advise the customer on how to properly insert and remove the lenses as well as the cleaning and care techniques.`,
  },
  {
    step: 'First Overnight Review',
    answer: `The customer is required to return to the store for a review after the first overnight wear. Appointment should be scheduled on the next day and within two hours of lens removal. The optometrist will determine if the lenses are properly fitted for the customer.`,
  },
  {
    step: 'One Week Review',
    answer: `Significant results should be achieved after one week of daily overnight wear. The optometrist will perform another review to ensure that the lenses are well-fitted for the customer. Appointment for this review session should be in the morning.`,
  },
  {
    step: 'One Month Review',
    answer: `The customer is required to return to the store for a review after one month of regular wear. Appointment should be scheduled for the evening when the lenses have been removed for 6 to 8 hours.`,
  },
  {
    step: 'Three Months Review',
    answer: `The customer is required to return to the store for a review after three months of regular wear. The optometrist will also conduct general lens cleaning during this review session.`,
  },
  {
    step: 'Half Annual Review',
    answer: `Depending on individual eye condition, the customer should be able to achieve stable, spectacle-free vision after the first 3 to 6 months of consistent daily wear. The customer will need to return for review and general lens cleaning every half annually.`,
  },
];

export const DISCOUNTS = [
  '50% Off for lenses of the same fitting and the same prescription',
  '$200 Off per lens of different fitting and/or different prescription',
];

export const TERM_AND_CONDITION = [
  'Offer is limited to lenses used by the same wearer only and is non-transferrable',
  'Offer does not include 1 complimentary set of solutions and 1 complimentary pair of spectacles worth up to $198',
  'Discount applies to the usual retail price of a pair of Ortho-K lenses priced from $1,688 depending on the complexity of the case',
  'Items sold are not refundable nor exchangeable',
  'Offer is valid while stocks last',
  'Offer cannot be used in conjunction with other discounts, vouchers and/or promotions',
  'The management reserves the right to amend the offer and the terms and conditions without prior notice',
];

export const STORES = [
  {
    img: `${baseImgUrl}/owndays-mbs-premium-concept-store.webp`,
    altText: 'OWNDAYS MBS Premium Concept Store',
    storeName: 'OWNDAYS MBS Premium Concept Store',
    storeLink: '/stores/sg?storeId=OD1007',
  },
  {
    img: `${baseImgUrl}/owndays-takashimaya-sc-premium-concept-store.webp`,
    altText: 'OWNDAYS Takashimaya S.C. Premium Concept Store',
    storeName: 'OWNDAYS Takashimaya S.C. Premium Concept Store',
    storeLink: '/stores/sg?storeId=OD1020',
  },
];

export const INSERTION_SLIDES = [
  {
    step: '01',
    imgSrc: `${baseImgUrl}/list-step-01.svg`,
    description: 'Wash hands with soap and dry thoroughly',
  },
  {
    step: '02',
    imgSrc: `${baseImgUrl}/list-step-02.svg`,
    description: 'Rinse lens with saline or multi-purpose solution',
  },
  {
    step: '03',
    imgSrc: `${baseImgUrl}/list-step-03.svg`,
    description: 'Apply a drop of non-preservative eye drop on lens',
  },
  {
    step: '04',
    imgSrc: `${baseImgUrl}/list-step-04.svg`,
    description: 'Apply a drop of non-preservative eye drop on lens',
  },
  {
    step: '05',
    imgSrc: `${baseImgUrl}/list-step-05.svg`,
    description:
      'Hold top eyelid with fingers of the non-master hand and bottom eyelid with the other fingers of the master hand',
  },
  {
    step: '06',
    imgSrc: `${baseImgUrl}/list-step-06.svg`,
    description: 'Insert lens onto the eye while looking directly in the mirror',
  },
];

export const REMOVAL_SUCTION_PUMP_SLIDES = [
  {
    step: '01',
    imgSrc: `${baseImgUrl}/list-step-01.svg`,
    description: 'Wash hands with soap and dry thoroughly',
  },
  {
    step: '02',
    imgSrc: `${baseImgUrl}/list-step-02.svg`,
    description:
      'Instill 1-2 drops of non-preservative eye drop onto the eye to allow lens to move slightly',
  },
  {
    step: '03',
    imgSrc: `${baseImgUrl}/list-step-07.svg`,
    description: 'Place suction pump at the centre of the lens',
  },
  {
    step: '04',
    imgSrc: `${baseImgUrl}/list-step-06.svg`,
    description: 'Lens will be removed by suction force from the pump',
  },
];

export const REMOVAL_FINGERS_SLIDES = [
  {
    step: '01',
    imgSrc: `${baseImgUrl}/list-step-01.svg`,
    description: 'Wash hands with soap and dry thoroughly',
  },
  {
    step: '02',
    imgSrc: `${baseImgUrl}/list-step-08.svg`,
    description:
      'Instill 1-2 drops of non-preservative eye drop onto the eye to allow lens to move slightly',
  },
  {
    step: '03',
    imgSrc: `${baseImgUrl}/list-step-05.svg`,
    description: 'Hold top and bottom eyelid using fingers of different hands',
  },
  {
    step: '04',
    imgSrc: `${baseImgUrl}/list-step-06.svg`,
    description: 'Press both eyelids simultaneously to push lens out',
  },
];

export const CLEANING_CARE = [
  {
    title: 'Daily',
    altText: '6 hours',
    imgSrc: `${baseImgUrl}/hours-circle-01.svg`,
    description:
      'Use multi-purpose solution to rinse and rub lens after each wear and soak lens for minimum 6 hours before the next wear.',
  },
  {
    title: 'Weekly',
    altText: '20 to 30 minutes / 6 hours',
    imgSrc: `${baseImgUrl}/hours-circle-02.svg`,
    description:
      'Soak lens in protein remover once a week for 20 to 30 minutes. Then use multi-purpose solution to rinse and rub lens before soaking lens for minimum 6 hours before the next wear.',
  },
];

export const FAQS = [
  {
    question: 'Is Ortho-K comfortable to wear?',
    answer:
      'There may be slight lens awareness and discomfort right after putting on the lenses. However, you will not “feel” the lenses during the overnight wear.',
  },
  {
    question: 'Is Ortho-K safe?',
    answer:
      'Ortho-K is a non-surgical, non-invasive treatment that is safe and reversible. The complications arising from the use of regular contact lenses due to poor hygiene and improper handling such as swimming and showering with the lenses may be minimised in the case of Ortho-K if proper cleaning and care is maintained.',
  },
  {
    question: 'What is the difference between Ortho-K and LASIK?',
    answer:
      'Ortho-K is a non-surgical, non-invasive treatment that is safe and reversible. On the other hand, LASIK is a surgical procedure that is irreversible. Ortho-K is recommended for people who prefer not to undergo LASIK surgery in order to achieve spectacle-free vision or those who are not suitable candidates for LASIK surgery.',
  },
  {
    question: 'How long should Ortho-K be worn each time?',
    answer: 'Ortho-K lenses should be worn for a minimum of 6 to 8 hours daily during sleep.',
  },
  {
    question: 'How long does it take to experience the full effects of Ortho-K?',
    answer:
      'In general, it takes approximately 10 to 14 days to achieve optimal vision with consistent daily overnight wear. However, results will also depend on individual eye condition.',
  },
  {
    question: 'Can I wear Ortho-K lenses if I have dry eyes?',
    answer:
      'Yes, Ortho-K lenses are suitable for people with mild dry eyes. Dry eye syndrome is usually caused by external factors such as dusty or air-conditioned environment. Since Ortho-K lenses are worn during sleep with the eyes closed, dry eye syndrome may be minimised during Ortho-K treatment.',
  },
  {
    question: 'Can I walk in to any store to purchase Ortho-K?',
    answer:
      'Ortho-K is available exclusively at our premium concept stores in MBS and Takashimaya S.C. You will have to book an appointment for a pre-assessment with one of our qualified optometrists who will determine your suitability for Ortho-K treatment. The pre-assessment will take approximately 45 minutes. Appointments are available for online booking only.',
    links: [
      {
        text: 'Click here',
        url: '/stores/schedule/OD1007',
        suffix: 'to book an appointment at OWNDAYS MBS Premium Concept Store',
      },
      {
        text: 'Click here',
        url: '/stores/schedule/OD1020',
        suffix: 'to book an appointment at OWNDAYS Takashimaya S.C. Premium Concept Store',
      },
    ],
  },
  {
    question: 'Do I have to make an appointment to purchase Ortho-K?',
    answer:
      'Yes, you will have to book an appointment for a pre-assessment with one of our qualified optometrists who will determine your suitability for Ortho-K treatment. The pre-assessment will take approximately 45 minutes. Appointments are available for online booking only.',
    links: [
      {
        text: 'Click here',
        url: '/stores/schedule/OD1007',
        suffix: 'to book an appointment at OWNDAYS MBS Premium Concept Store',
      },
      {
        text: 'Click here',
        url: '/stores/schedule/OD1020',
        suffix: 'to book an appointment at OWNDAYS Takashimaya S.C. Premium Concept Store',
      },
    ],
  },
  {
    question: 'Can I wear colour contact lenses after starting Ortho-K treatment?',
    answer:
      'No, we do not recommend wearing colour contact lenses after starting Ortho-K treatment. This is because the cornea curvature changes from wearing Ortho-K lenses and this affects the fitting of soft lenses which include colour contact lenses.',
  },
  {
    question: 'How long can a pair of Ortho-K lenses last?',
    answer:
      'Ortho-K lenses are able to last for 1.5 years on average. Depending on your care and handling habits, more frequent replacement may be needed.',
  },
  {
    question: 'Am I suitable for Ortho-K if I have irregular sleeping patterns?',
    answer:
      'You should have 6 to 8 hours of consistent closed-eye rest in order to achieve the optimal results. People who have irregular sleeping patterns, such as those who are on rotational shift work, are not suitable candidates for Ortho-K since they are unable to wear Ortho-K lenses at the same time every day.',
  },
];
