import React from 'react';

import { DotPalette } from './DotPalette';

const defaultSetting = {
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  dots: true,
  fade: true,
  draggable: false,
  infinite: true,
  swipe: false,
  touchMove: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        draggable: true,
        infinite: true,
        swipe: true,
        touchMove: true,
      },
    },
  ],
};

export const CustomStandardDots = props => {
  const backgroundColors = {
    default: ['#525252', '#B19A97', '#DDD6DA'],
    'SNP2013N-2S': ['#000', '#7C4740', '#9A986A'],
    'SNP2015N-2S': ['#000', '#7C4740', '#BB8E78'],
  };

  return <DotPalette {...props} backgroundColors={backgroundColors} />;
};

export const CustomBusinessDots = props => {
  const backgroundColors = {
    default: ['#525252', '#413D45', '#4E70A2'],
    'SNP1021X-4S': ['#525252', '#6F6B73', '#767F9A'],
    'SNP2012N-2S': ['#000', '#7C4740', '#2F446D'],
  };

  return (
    <DotPalette
      {...props}
      backgroundColors={backgroundColors}
      containerClassName="product-od-snap__container--right"
    />
  );
};

export const CustomModernDots = props => {
  const backgroundColors = {
    default: ['#525252', '#E0C5BA', '#7C4740'],
    'SNP1011N-1S': ['#000', '#E0BE47', '#BCC4D3'],
    'SNP1018N-3S': ['#525252', '#E0BE47', '#BCC4D3'],
  };

  return <DotPalette {...props} backgroundColors={backgroundColors} />;
};

export const CustomClassicDots = props => {
  const backgroundColors = {
    default: ['#525252', '#6F6B73', '#7C4740'],
    'SNP1023X-4S': ['#525252', '#623731', '#926B66'],
    'SNP1019N-3S': ['#525252', '#E0BE47', '#BCC4D3'],
  };

  return (
    <DotPalette
      {...props}
      backgroundColors={backgroundColors}
      containerClassName="product-od-snap__container--right"
    />
  );
};

const initialSlides = {
  'SNP2018N-4S': 0,
  'SNP2013N-2S': 2,
  'SNP2015N-2S': 2,
  'SNP1020X-4S': 1,
  'SNP1021X-4S': 2,
  'SNP2012N-2S': 2,
  'SNP1024N-4S': 2,
  'SNP1011N-1S': 1,
  'SNP1018N-3S': 2,
  'SNP1022X-4S': 2,
  'SNP1023X-4S': 2,
  'SNP1019N-3S': 1,
};

const dotComponents = {
  'SNP2018N-4S': CustomStandardDots,
  'SNP2013N-2S': CustomStandardDots,
  'SNP2015N-2S': CustomStandardDots,
  'SNP1020X-4S': CustomBusinessDots,
  'SNP1021X-4S': CustomBusinessDots,
  'SNP2012N-2S': CustomBusinessDots,
  'SNP1024N-4S': CustomModernDots,
  'SNP1011N-1S': CustomModernDots,
  'SNP1018N-3S': CustomModernDots,
  'SNP1022X-4S': CustomClassicDots,
  'SNP1023X-4S': CustomClassicDots,
  'SNP1019N-3S': CustomClassicDots,
};

const createSettings = sku => {
  const initialSlide = initialSlides[sku];
  const CustomDotsComponent = dotComponents[sku];

  return {
    ...defaultSetting,
    initialSlide,
    beforeChange: (oldIndex, newIndex) => {
      initialSlides[sku] = newIndex;
    },
    appendDots: dots => (
      <CustomDotsComponent dots={dots} initialSlide={initialSlides[sku]} sku={sku} />
    ),
  };
};

// Generate settings for each SKU
export const settingsForSNP2018N4S = createSettings('SNP2018N-4S');
export const settingsForSNP2013N2S = createSettings('SNP2013N-2S');
export const settingsForSNP2015N2S = createSettings('SNP2015N-2S');
export const settingsForSNP1020X4S = createSettings('SNP1020X-4S');
export const settingsForSNP1021X4S = createSettings('SNP1021X-4S');
export const settingsForSNP2012N2S = createSettings('SNP2012N-2S');
export const settingsForSNP1024N4S = createSettings('SNP1024N-4S');
export const settingsForSNP1011N1S = createSettings('SNP1011N-1S');
export const settingsForSNP1018N3S = createSettings('SNP1018N-3S');
export const settingsForSNP1022X4S = createSettings('SNP1022X-4S');
export const settingsForSNP1023X4S = createSettings('SNP1023X-4S');
export const settingsForSNP1019N3S = createSettings('SNP1019N-3S');
