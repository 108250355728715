import React, { useEffect } from 'react';
import './GundamZakuhead.scss';
import Breadcrumb from '../../../../desktop/components/Common/Breadcrumb';
const GundamZakuhead = () => {
  const baseUrl = 'https://www.owndays.com/sg/en/';
  const baseImgUrl = 'https://www.owndays.com/images/specials/products/gundam5-zakuhead/';
  const currentUrl = window.location.href;
  const pageName = currentUrl.split('/').pop();
  const products = [
    {
      src: `${baseImgUrl}gallery-1.webp`,
      desc: 'Monoeye lights up on opening/closing, along with an activation sound effect',
    },
    {
      src: `${baseImgUrl}gallery-2.webp`,
      desc: 'Space to hold 4 pairs of eyewear',
    },
    {
      src: `${baseImgUrl}gallery-3.webp`,
      desc: '2-step opening/closing mechanism reveals an exquisitely engineered internal structure',
    },
    {
      src: `${baseImgUrl}gallery-4.webp`,
      desc: 'Detachable antenna, a symbol of the commander rank',
    },
    {
      src: `${baseImgUrl}gallery-5.webp`,
      desc: 'Movable monoeye that makes it more realistic',
    },
  ];
  const socialMediaLinks = [
    {
      href: `https://www.facebook.com/sharer/sharer.php?u=${baseUrl}news/${pageName}`,
      title: 'Facebook',
      imgSrc: 'https://www.owndays.com/images/specials/products/huawei2/fb-icon.svg',
    },
    {
      href: `https://twitter.com/intent/tweet?url=${baseUrl}news/${pageName}`,
      title: 'X',
      imgSrc: 'https://www.owndays.com/images/specials/products/huawei2/tw-icon.svg',
    },
    {
      href: `http://line.me/R/msg/text/?${baseUrl}news/${pageName}`,
      title: 'LINE',
      imgSrc: 'https://www.owndays.com/images/specials/products/huawei2/line-icon.svg',
    },
  ];
  const specs = [
    {
      title: 'Product Size',
      detail: 'Approximately W403 x D357 x H443 mm',
    },
    {
      title: 'Packing Size',
      detail: 'Approximately W415 x D395 x H325 mm',
    },
    {
      title: 'Weight',
      detail: 'Approximately 3.7 kg (Including outer box and packaging)',
    },
    {
      title: 'Material',
      detail: 'ABS Resin',
    },
    {
      title: 'Battery',
      detail: '3 x AA alkaline batteries (1 set included for trial)',
    },
    {
      title: 'Storage Capacity',
      detail: '4 pairs of eyewear',
      note: '*Subject to the size of eyewear',
    },
    {
      title: 'Intended Users',
      detail: 'Aged 15 years and above',
    },
  ];
  useEffect(() => {
    const addScript = (src, onLoad) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = onLoad;
      document.head.appendChild(script);
      return script;
    };

    const addStylesheet = href => {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = href;
      document.head.appendChild(link);
      return link;
    };

    const scripts = [
      { src: 'https://cdn-script.com/ajax/libs/jquery/3.7.1/jquery.js', onLoad: null },
      { src: 'https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.js', onLoad: null },
      {
        src: 'https://cdnjs.cloudflare.com/ajax/libs/Swiper/8.4.7/swiper-bundle.min.js',
        onLoad: null,
      },
      {
        src: 'https://cdnjs.cloudflare.com/ajax/libs/uikit/3.21.5/js/uikit-core.min.js',
        onLoad: null,
      },
    ];

    const stylesheets = [
      'https://cdnjs.cloudflare.com/ajax/libs/Swiper/8.4.7/swiper-bundle.css',
      'https://cdnjs.cloudflare.com/ajax/libs/fancybox/3.5.7/jquery.fancybox.css',
      'https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.css',
    ];

    const scriptElements = scripts.map(({ src, onLoad }) => addScript(src, onLoad));
    const styleElements = stylesheets.map(href => addStylesheet(href));

    const getBodyWidth = () => {
      document.documentElement.style.setProperty('--body-w', document.body.clientWidth + 'px');
    };

    getBodyWidth();
    window.addEventListener('resize', getBodyWidth);
    window.onload = () => {
      AOS.init({
        once: true,
        duration: 1000,
      });
      $('body').addClass('gundam-head').append(`
        <script>
          new Swiper('.function__slider', {
            slidesPerView: 'auto',
            spaceBetween: 15,
            centeredSlides: true,
            loop: true,
            shortSwipes: false,
            longSwipesMs: 0,
            longSwipesRatio: 0.015,
          });
        </script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/uikit/3.21.5/js/components/parallax.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/fancybox/3.5.7/jquery.fancybox.min.js"></script>
      `);

      function handleScroll(targetSelector, actionCallback) {
        $(targetSelector).each(function () {
          const $target = $(this);

          if ($target.hasClass('aos-animate') && !$target.hasClass('is-shown')) {
            $target.addClass('is-shown');
            actionCallback($target);
          }
        });
      }

      $(window).on('scroll', function () {
        handleScroll('.c-modal-video', function ($target) {
          const setAutoPlay = $target
            .find('iframe')
            .attr('src')
            .replace('autoplay=0', 'autoplay=1');
          $target.find('iframe').attr('src', setAutoPlay);
        });
      });

      const handleClickSmoothScroll = e => {
        const target = e.target;
        const targetId = target.getAttribute('href');

        if (targetId && targetId.startsWith('#')) {
          e.preventDefault();
          const targetElement = document.querySelector(targetId);
          const headerHeight = document.querySelector('.header-placeholder')?.offsetHeight || 0;
          const offset = parseInt(target.getAttribute('data-scroll-offset'), 10) || 25;
          const scrollPosition = targetElement.offsetTop - headerHeight - offset;

          window.scrollTo({
            top: scrollPosition,
            behavior: 'smooth',
          });
        }
      };

      const links = document.querySelectorAll('.l-gundam5 a[href^="#"]');
      links.forEach(link => link.addEventListener('click', handleClickSmoothScroll));

      setTimeout(function () {
        $('[data-fancybox="gallery"]').fancybox({
          buttons: ['close'],
          loop: true,
          animationEffect: false,
          idleTime: false,
          afterLoad(instance, current) {
            current.$image.attr('alt', current.opts.$orig.find('img').attr('alt'));
          },
          baseTpl: `
            <div class="fancybox-container fancybox-gallery" role="dialog" tabindex="-1">
              <div class="fancybox-bg"></div>
              <div class="fancybox-inner">
                <div class="fancybox-toolbar">{{buttons}}</div>
                <div class="fancybox-stage">
                  <div class="fancybox-stage-bottom">
                    <div class="fancybox-caption">
                      <div class="fancybox-caption__body"></div>
                    </div>
                    <div class="fancybox-navigation">{{arrows}}</div>
                    <div class="fancybox-infobar"><span data-fancybox-index></span>&nbsp;/&nbsp;<span data-fancybox-count></span></div>
                  </div>
                </div>
              </div>
            </div>`,

          btnTpl: {
            arrowLeft: `
              <button data-fancybox-prev class="fancybox-button fancybox-button--arrow_left" title="{{PREV}}">
                <i></i>
              </button>`,
            arrowRight: `
              <button data-fancybox-next class="fancybox-button fancybox-button--arrow_right" title="{{NEXT}}">
                <i></i>
              </button>`,
            close: `
              <button data-fancybox-close class="fancybox-button fancybox-button--close" title="{{CLOSE}}">
                <i></i>
              </button>`,
          },
        });
        $('.function__slider .swiper-slide button').on('click', function () {
          const $this = $(this);
          const $li = $this.parents('li');
          const $index = $li.data('swiper-slide-index');
          $('#gallery_img_popup > a').eq($index).trigger('click');
        });
      }, 150);
    };

    return () => {
      window.removeEventListener('resize', getBodyWidth);
      scriptElements.forEach(script => document.head.removeChild(script));
      styleElements.forEach(link => document.head.removeChild(link));
      links.forEach(link => link.removeEventListener('click', handleClickSmoothScroll));
    };
  }, []);
  return (
    <>
      <Breadcrumb
        breadcrumbData={[
          {
            label: 'Homepage',
            link: 'www.lenskart.com',
          },
          {
            label: 'News',
            link: 'sg/en/news',
          },
          {
            label: 'ZEON × OWNDAYS ZAKU HEAD CASE',
          },
        ]}
        dataLocale={{
          SYMBOL_GREATER: '>',
        }}
      />
      <div className="l-gundam5">
        {/* MAIN */}
        <section className="main">
          <div className="l-gundam5__container">
            <div className="main__content" data-uk-parallax="y: 0,-10%;">
              <h1>ZAKU HEAD CASE</h1>
              <small>MS-06S CHAR’S ZAKUII</small>
              <img alt="ZEON × OWNDAYS" className="main__logo" src={`${baseImgUrl}logo.svg`} />
              <p>
                Limited to <strong>2,500</strong> units worldwide
              </p>
            </div>
            <img alt="ZEON" className="main__zeon-logo" src={`${baseImgUrl}zeon-logo.svg`} />
            <a
              aria-label="Scroll down"
              className="main__scroll-down"
              data-scroll-offset="-50"
              href="#concept"
            ></a>
          </div>
          <picture className="main__img">
            <source media="(min-width:768px)" srcSet={`${baseImgUrl}main-pc.webp`} />
            <img
              alt="ZEON × OWNDAYS ZAKU HEAD CASE"
              data-uk-parallax="y: 0,10%"
              src={`${baseImgUrl}main-sp.webp`}
            />
          </picture>
        </section>

        {/* CONCEPT */}
        <section className="concept" id="concept">
          <div className="l-gundam5__container">
            <h2 className="l-gundam5__title l-gundam5__title--right-sp">CONCEPT</h2>
            <div className="concept__top">
              <picture
                className="concept__img"
                data-uk-parallax="y: 10%,0,-5%; target: .concept__top; easing: 0;"
              >
                <source media="(min-width:768px)" srcSet={`${baseImgUrl}concept-img-pc.webp`} />
                <img alt="ZAKU" src={`${baseImgUrl}concept-img-sp.webp`} />
              </picture>
              <p className="concept__desc">
                From Mobile Suit Gundam, one of the most iconic Japanese anime series that has
                transcended generations, comes the Zaku Head Case, an eyewear storage case modelled
                after Char Aznable’s custom Zaku in the largest-ever 1:7 scale. Besides being able
                to house 4 pairs of eyewear, the head case showcases a lighted monoeye and Zaku’s
                characteristic activation sound effect upon opening and closing. The monoeye, an
                iconic feature of Zeon mobile suits, can be moved left and right.
              </p>
            </div>
            <a
              data-aos
              data-fancybox
              className="c-modal-video c-modal-video--iframe"
              data-aos-offset="-300"
              href="https://youtu.be/__kz_GvsgzQ"
            >
              <iframe
                allow="autoplay"
                src="https://www.youtube.com/embed/__kz_GvsgzQ?autoplay=0&mute=1&loop=1&controls=0&showinfo=0&playlist=__kz_GvsgzQ"
              ></iframe>
            </a>
          </div>
        </section>

        {/* FUNCTION */}
        <section className="function">
          <div className="l-gundam5__container">
            <h2 className="l-gundam5__title">FUNCTION</h2>
          </div>
          <div className="function__slider swiper">
            <ul className="swiper-wrapper">
              {products.map((product, index) => (
                <li key={index} className="swiper-slide">
                  <div className="function__slider-img">
                    <img alt={product.desc} src={product.src} />
                    <button aria-label="zoom" type="button"></button>
                  </div>
                  <p className="function__slider-desc">{product.desc}</p>
                </li>
              ))}
            </ul>
          </div>
          <div id="gallery_img_popup">
            {products.map((product, index) => (
              <a key={index} data-caption={product.desc} data-fancybox="gallery" href={product.src}>
                <img alt={product.desc} />
              </a>
            ))}
          </div>

          {/* FUNCTION - TRY AR */}
          <div className="l-gundam5__container">
            <div className="function__ar">
              <h3 className="function__ar-title">TRY AR</h3>
              <div className="function__ar-img">
                <img
                  alt="Get a ZAKU HEAD CASE in your room!"
                  className="function__ar-img-demo"
                  src={`${baseImgUrl}ARzaku.webp`}
                />
                <img
                  alt="QR Code"
                  className="function__ar-img-qr"
                  src={`${baseImgUrl}ar-qrcode-prod.webp`}
                />
              </div>
              <p className="function__ar-desc">
                Use AR (augmented reality) to see how the Zaku Head Case would fit in your room.
                <div className="function__ar-note">* Discrepancy in size perception may occur</div>
              </p>
            </div>
          </div>
          {/* FUNCTION - PRICE */}
          <div className="function__price">
            <div className="l-gundam5__container">
              <picture className="function__price-img">
                <source media="(min-width:768px)" srcSet={`${baseImgUrl}zaku-head-pc.webp`} />
                <img alt="ZAKU HEAD CASE" src={`${baseImgUrl}zaku-head-sp.webp`} />
              </picture>
              <h4 className="function__price-title">
                <span>ZAKU HEAD CASE</span>
                <small>Limited to 2,500 units worldwide</small>
              </h4>
              <p className="function__price-value">S$498</p>
              <a className="l-gundam5__btn" href="/sg/en/gundam-zaku-case3a.html">
                Purchase Online
              </a>
            </div>
          </div>
        </section>

        {/* SPEC */}
        <section className="spec">
          <div className="l-gundam5__container">
            <h2 className="l-gundam5__title l-gundam5__title--center">SPECIFICATIONS</h2>
            <div className="spec__list">
              <ul>
                {specs.map((spec, index) => (
                  <li key={index}>
                    <div className="spec__list--title">
                      <p>{spec.title}</p>
                    </div>
                    <div className="spec__list--detail">
                      <p>{spec.detail}</p>
                      {spec.note && <p className="note">{spec.note}</p>}
                    </div>
                  </li>
                ))}
              </ul>
              <div className="spec-download">
                <a
                  href="https://www.owndays.com/images/specials/products/gundam5-zakuhead/head_torisetu_web.pdf"
                  rel="noreferrer"
                  target="_blank"
                >
                  <span>Download Instruction Manual (PDF)</span>
                </a>
              </div>
            </div>
            <div className="spec__note spec__note-section">
              <h2>Precautions</h2>
              <p>
                • This is a delicate product that should be handled with care. To reduce the risk of
                damage or accident, do not drop, hit or subject product to strong impact.
                <br />
                • Do not place product on slanted surfaces
                <br />• Product is not waterproof/splash-proof. Keep away from all sources of
                liquid.
              </p>
            </div>
          </div>
        </section>

        {/* BANNER */}
        <section className="banner">
          <div className="banner__container">
            <picture>
              <source media="(min-width:768px)" srcSet={`${baseImgUrl}banner-bottom-pc.webp`} />
              <img
                alt="Zeon exclusive spectacles & sunglasses"
                src={`${baseImgUrl}banner-bottom-sp.webp`}
              />
            </picture>
            <div className="banner__detail">
              <div className="banner-category">
                <p>Simultaneous Release</p>
              </div>
              <div className="logo-zeon-owndays">
                <img alt="ZEON × OWNDAYS" src={`${baseImgUrl}logo.svg`} />
              </div>
              <div className="sub-zeon-owndays">
                <p>ZEON EXCLUSIVE SPECTACLES & SUNGLASSES</p>
              </div>
              <div className="btn-banner">
                <a className="btn-gold" href="/sg/en/news/gundam5-zeon">
                  View More
                </a>
              </div>
            </div>
          </div>
        </section>

        {/* SHARE */}
        <section className="share">
          <div className="share__container">
            <h2>SHARE</h2>
            <ul className="list-sns">
              {socialMediaLinks.map((link, index) => (
                <li key={index}>
                  <a href={link.href} rel="noopener noreferrer" target="_blank" title={link.title}>
                    <img alt={link.title} src={link.imgSrc} />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </section>
      </div>
    </>
  );
};

export default GundamZakuhead;
