import React, { useEffect, useState } from 'react';
import * as productAction from '../../../../actionCreators/product';
import './SelectLenses.scss';
import { useDispatch, useSelector, connect } from 'react-redux';
import { addToCart } from '../../../../actionCreators/cart';
import ButtonOwnDays from '../../../../CommonComponents/buttons/ButtonOwnDays/ButtonOwnDays';
import { showGlobalHeaderFooter } from '../../../../actionCreators/header';
import apiClient from '../../../../helpers/apiClient';
import { apiPath } from '../../../../config';
import { bindActionCreators } from 'redux';
import { odPushGA4Event } from '../../../../utils/gaFour';
import { odPushTTQEvent } from '../../../../utils/tiktokPixel';
import { odPushClevertapEvent } from '../../../../utils/clevertap';
import { setToastMessage } from '../../../../actionCreators/common';
import SelectLensesHeader from './SelectLensesHeader';
import PrescriptionType from './PrescriptionType';
import { getProductPrice } from '../../../helper';
import Packages from './Packages';
import { Helmet } from 'react-helmet';

const SELECT_LENS = 'select_lens';
const SELECT_PRESCRIPTION = 'select_prescription';
const FRAME_ONLY = 'frame_only';
const steps = {
  select_prescription: {
    label: 'Back',
  },
  select_lens: {
    label: 'Back',
  },
  frame_only: {
    label: 'Back',
  },
};

const getProductName = (productName, color) => {
  const newName = productName
    ?.replace('C1', color)
    .replace('C2', color)
    .replace('C3', color)
    .replace('C4', color)
    .replace('C5', color)
    .replace('C6', color)
    .replace('C7', color);

  return newName || productName;
};

const SelectLenses = props => {
  const [selectedPrescriptionType, setSelectedPrescriptionType] = useState(null);
  const [currentStep, setCurrentStep] = useState(SELECT_PRESCRIPTION);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedCoating, setSelectedCoating] = useState(null);
  const [packages, setPackages] = useState([]);
  const [packagesZeroPower, setPackagesZeroPower] = useState([]);

  useEffect(() => {
    console.log(
      'selecte lensess newwwww ===>',
      selectedPrescriptionType,
      packages,
      selectedPackage,
      selectedCoating,
      currentStep
    );
  }, [selectedPrescriptionType, packages, selectedPackage, selectedCoating, currentStep]);

  useEffect(() => {
    setSelectedCoating(null);
    setSelectedPackage(null);
  }, [selectedPrescriptionType]);

  const { userInfo, platform } = props;
  const productId = props?.match?.params?.id;
  const history = props?.history;
  const dispatch = useDispatch();
  const product = useSelector(state => state?.product?.productData?.result);
  const { CONTINUE, CURRENCY_SYMBOL, CURRENCY_CODE } = useSelector(state => state?.locale);
  const showGlobalHeaderFooter = props?.showGlobalHeaderFooter;
  const price = getProductPrice(product);

  useEffect(() => {
    if (selectedPrescriptionType) {
      odPushGA4Event('cta_click', {
        userInfo,
        platform,
        ctaName: `${product.type}-package-screen-${product.color}-${
          selectedPrescriptionType ? 'Power' : 'No-Power'
        }-${product.type === 'Eyeglasses' || product.type === 'eyeframe' ? 'Eye' : 'Sun'}`,
        ctaFlowAndPage: `${product.type}-package-screen`,
      });
    }
  }, [selectedPrescriptionType]);

  useEffect(() => {
    showGlobalHeaderFooter({ header: true, footer: false });

    if (productId) {
      dispatch(productAction.getProductData(productId));
    }

    odPushGA4Event('page_view', {
      screenName: 'add-lens-type-page',
      userInfo,
      platform,
    });

    window.clevertapScreenName = 'add-lens-type-page';

    if (typeof window.clevertap !== 'undefined') {
      window.clevertap.event.push('page_view', {
        screen_name: window.clevertapScreenName,
      });
    }

    return () => {
      window.clevertapScreenName = null;
    };
  }, []);

  useEffect(() => {
    if (product) {
      const client = apiClient();
      client.setHeader();
      client
        .get(
          `${apiPath.lensPackages}/${productId}/packages?power_type=single_vision&frame_type=${product?.frameTypeKey}`
        )
        .then(res => {
          if (Number(res?.status) === 200) {
            const packs = res?.result;
            if (packs?.packages?.length > 0) {
              setPackages(packs?.packages);
            } else {
              setPackages([]);
            }
          } else {
            setPackages([]);
          }
        })
        .catch(() => {
          setPackages([]);
        });

      client
        .get(
          `${apiPath.lensPackages}/${productId}/packages?power_type=zero_power&frame_type=${product?.frameTypeKey}`
        )
        .then(res => {
          if (Number(res?.status) === 200) {
            const packs = res?.result;
            if (packs?.packages?.length > 0) {
              setPackagesZeroPower(packs?.packages);
            } else {
              setPackagesZeroPower([]);
            }
          } else {
            setPackagesZeroPower([]);
          }
        })
        .catch(() => {
          setPackagesZeroPower([]);
        });
    }
  }, [product]);

  useEffect(() => {
    if (!product?.id) return;

    const gaAddToCartData = JSON.parse(sessionStorage.getItem('ga-add-to-cart-data')) || {};

    sessionStorage.setItem(
      'ga-add-to-cart-data',
      JSON.stringify({
        ...gaAddToCartData,
        [product.id]: {
          item_category3: selectedPackage?.id,
          item_category4: selectedCoating?.id,
        },
      })
    );
  }, [product?.id, selectedPackage, selectedCoating]);

  useEffect(() => {
    if (selectedPrescriptionType === 'frame_only') {
      setCurrentStep(FRAME_ONLY);
    }
    if (selectedPrescriptionType === 'single_vision' || selectedPrescriptionType === 'zero_power') {
      setCurrentStep(SELECT_LENS);
    }
  }, [selectedPrescriptionType]);

  useEffect(() => {
    if (selectedPrescriptionType && selectedPackage && selectedCoating) {
      odPushClevertapEvent('Lens Selected', {
        lensType: selectedPrescriptionType,
        package: selectedPackage,
        coating: selectedCoating,
      });
    }
  }, [selectedCoating, selectedPackage, selectedPrescriptionType]);

  const onContinue = () => {
    const gaPLPSelectItemData = JSON.parse(sessionStorage.getItem('ga-plp-select-item-data')) || {};
    const gaAddToCartData = JSON.parse(sessionStorage.getItem('ga-add-to-cart-data')) || {};

    if (!selectedPrescriptionType) {
      dispatch(setToastMessage({ message: 'Please select prescription type', timeout: 2000 }));
      return null;
    }

    if (!selectedCoating && selectedPrescriptionType !== 'frame_only') {
      dispatch(setToastMessage({ message: 'Please select package', timeout: 2000 }));
      return null;
    }

    if (selectedCoating && selectedPackage && selectedPrescriptionType) {
      odPushGA4Event('add_to_cart', {
        userInfo,
        productData: product,
        gaPLPSelectItemData,
        gaAddToCartData,
      });

      odPushTTQEvent('AddToCart', {
        userInfo,
        productData: product,
      });

      dispatch(
        addToCart({
          productId,
          powerType: selectedPrescriptionType === 'zero_power' ? 'zero_power' : 'single_vision',
          packageId: selectedPackage?.id,
          addOns: selectedCoating?.id,
        })
      );
      history.push(`/cart`);
    }

    // If I know my power open select lens screen and select lens
    // If I don't know my power add to cart without lens
    // Without prescription open select lens
    if (selectedPrescriptionType === 'frame_only') {
      // add to cart without select lens
      dispatch(addToCart({ productId, powerType: 'Frame Only' }));
      history.push('/cart');
    } else {
      setCurrentStep(SELECT_LENS);
    }
  };

  const handleBack = () => {
    if (currentStep === SELECT_LENS || currentStep === FRAME_ONLY) {
      setCurrentStep(SELECT_PRESCRIPTION);
      setSelectedPrescriptionType(null);
      setSelectedPackage(null);
      setSelectedCoating(null);
    }

    if (currentStep === SELECT_PRESCRIPTION) {
      // send to back screen on home page
      history.goBack();
    }
  };

  const onCross = () => {
    history.goBack();
  };

  if (product) {
    return (
      <div className="odslv">
        <Helmet
          meta={[
            {
              name: 'description',
              content: 'Select Lens｜OWNDAYS ONLINE STORE - OPTICAL SHOP',
            },
          ]}
          title="Select Lens｜OWNDAYS ONLINE STORE - OPTICAL SHOP"
        />
        <div className="odslv__left">
          <div className="odslv__left__content">
            <img
              alt={product?.fullName}
              className="odslv__left__product-img"
              src={product?.thumbnailImage}
            />
            <div className="odslv__left__product-name">
              {getProductName(product?.fullName, product?.frameColour)}
            </div>
          </div>
        </div>
        <div className="odslv__right">
          <div className="odslv__right__main">
            <div className="odslv__right__main__content">
              <SelectLensesHeader
                currentStep={currentStep}
                steps={steps}
                onBack={handleBack}
                onCross={onCross}
              />
              {currentStep === SELECT_PRESCRIPTION && (
                <PrescriptionType
                  selectedPrescriptionType={selectedPrescriptionType}
                  setSelectedPrescriptionType={setSelectedPrescriptionType}
                />
              )}
              {currentStep === SELECT_LENS && (
                <div className="select-lens-page-nested">
                  <h1>Select Lens</h1>
                  <Packages
                    packages={
                      selectedPrescriptionType === 'zero_power' ? packagesZeroPower : packages
                    }
                    selectedCoating={selectedCoating}
                    selectedPackage={selectedPackage}
                    setSelectedCoating={setSelectedCoating}
                    setSelectedPackage={setSelectedPackage}
                  />
                </div>
              )}
              {currentStep === FRAME_ONLY && (
                <div className="od-fram-only-description">
                  <h1>Lens Replacement Voucher</h1>
                  <ul>
                    <li>
                      Get a free replacement of standard lenses for your OWNDAYS glasses bought
                      online. Upgrades cost extra.
                    </li>
                    <li>
                      Redeem in OWNDAYS stores in Singapore, but kids under 8 needing an eye test,
                      go to a store with optometrists.
                    </li>
                    <li>
                      The voucher is good for two months. Keep it safe; lost or damaged ones can't
                      be replaced. Terms may change anytime.
                    </li>
                  </ul>
                </div>
              )}
            </div>
            {(currentStep === SELECT_LENS || currentStep === FRAME_ONLY) && (
              <div className="odslv__right__footer">
                <div className="odslv__right__footer--content">
                  {/* We support single vision powers for online orders */}
                </div>
                <div className="odslv__right__footer--action">
                  <div className="odslv__right__footer--action-price">
                    Price:{' '}
                    <strong>{`${CURRENCY_CODE}${CURRENCY_SYMBOL}${
                      selectedCoating ? getProductPrice(selectedCoating) + price : price
                    }`}</strong>
                  </div>
                  <ButtonOwnDays
                    fontClass="od-font-bold"
                    mode="grey"
                    style={{ padding: '14px 0' }}
                    text={CONTINUE}
                    width="275px"
                    onClick={onContinue}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return null;
};

const mapStateToProps = state => ({
  userInfo: state.common.userInfo,
  platform: state.DTM.platform,
});

const mapDispatchToProps = dispatch => {
  return {
    showGlobalHeaderFooter: bindActionCreators(showGlobalHeaderFooter, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectLenses);
