import React, { Fragment, useState, useEffect } from 'react';
import AOS from 'aos';

import {
  NEWS_CATEGORIES,
  NEWS_LIST,
  PRODUCT_NEWS,
  OWNDAYS_MEETS_NEWS,
  CAMPAING_NEWS,
} from './getNews';

import './BlogNews.scss';
import 'aos/dist/aos.css';

export const BlogNews = () => {
  const [listNews, setListNews] = useState(NEWS_LIST);
  const [currentCategory, setCurrentCategory] = useState('#all');

  const hash = window.location.hash;

  const setNewsList = category => {
    setCurrentCategory(category);

    switch (category) {
      case '#owndays-meets':
        setListNews(OWNDAYS_MEETS_NEWS);
        break;

      case '#products':
        setListNews(PRODUCT_NEWS);
        break;

      case '#campaign':
        setListNews(CAMPAING_NEWS);
        break;

      default:
        setListNews(NEWS_LIST);
        break;
    }
  };

  const handleClickCategories = (e, category) => {
    e.preventDefault();

    setNewsList(category);

    window.history.pushState('', document.title, window.location.pathname + window.location.search);
  };

  useEffect(() => {
    if (hash) {
      setNewsList(hash);
    }
  }, [hash]);

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
    });
  }, []);

  return (
    <Fragment className="blog-news">
      <section className="blog-news__categories">
        <ul>
          {NEWS_CATEGORIES.map(item => {
            return (
              <li
                key={item.name}
                className={`${currentCategory === item.hash ? 'selected-new-category' : ''}`}
                onClick={e => handleClickCategories(e, item.hash)}
              >
                {item.name}
              </li>
            );
          })}
        </ul>
      </section>

      <section className="blog-news__container">
        <div className="blog-news__block blog-news__block--active">
          <ul className="blog-news__list blog-news__list--open">
            {listNews.map((item, index) => {
              return (
                <li key={item.title} data-aos="fade-up" data-aos-delay={index * 200}>
                  <a href={item.slug}>
                    <figure>
                      <div className="blog-news__list-thumbnail">
                        <img alt={item.title} src={item.image} />
                      </div>
                      <figcaption>
                        <h4 className="blog-news__list-category">{item.category}</h4>
                        <p className="blog-news__list-title">{item.title}</p>
                        <p className="blog-news__list-view-more">VIEW MORE</p>
                      </figcaption>
                    </figure>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </section>
    </Fragment>
  );
};
