import React from 'react';

export const MainButtons = () => {
  const buttons = [
    {
      href: '/sg/en/eyeglasses/brands/snap.html',
      text: 'See All OWNDAYS SNAP Products',
    },
    {
      href: '/stores/sg',
      text: 'Search for nearest OWNDAYS stores',
    },
  ];

  return (
    <div className="concept-owndays-snap__buttons">
      {buttons.map(button => (
        <a
          key={button.text}
          className="base-owndays-snap__btn base-owndays-snap--green concept-owndays-snap__buttons__size"
          href={button.href}
          rel="noreferrer"
          target="_blank"
        >
          <div>
            <span className="base-owndays-snap__btn__label-down">{button.text}</span>
            <span className="base-owndays-snap__btn__label-down">{button.text}</span>
          </div>
        </a>
      ))}
    </div>
  );
};
