import React, { useEffect, useState } from 'react';
import {
  validateUserInput,
  authenticateUser,
  sendValidateOtp,
  forgotPasswordAction,
  updateCustomer,
} from '../../../actionCreators/auth';
import './LoginSignup.scss';
import { useDispatch, useSelector } from 'react-redux';
import { validateLoginCreds, validateSingaporeMobileNumber } from './utils';
import apiClient from '../../../helpers/apiClient';
import { apiPath } from '../../../config';
import { setToastMessage } from '../../../actionCreators/common';
import { parseQueryParams } from '../../../own-days/helper';
import { odPushClevertapEvent } from '../../../utils/clevertap';

const Login = props => {
  const { history } = props;
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [isMobileLogin, setIsMobileLogin] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [otpValue, setOtpValue] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [timer, setTimer] = useState(30);
  const [timerRunning, setTimerRunning] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [forgotEmail, setForgotEmail] = useState('');
  const [visitReason, setVisitReason] = useState('buyGlasses');

  const dispatch = useDispatch();

  const userInputValidate = useSelector(state => state.auth?.userInputValidate);
  const loginError = useSelector(state => state.auth?.loginError);
  const isLogin = useSelector(state => state.common?.userInfo?.result?.login);
  const emailStatus = useSelector(state => state?.auth?.emailStatus);
  const forgotpassError = useSelector(state => state?.auth?.forgotpassError);
  const loginErr = useSelector(state => state?.auth?.loginError?.error);
  const userInputError = useSelector(state => state?.auth?.userInputErr?.message);
  const otpSentError = useSelector(state => state?.auth?.oTPSent?.error?.message);
  const otpSentToken = useSelector(state => state.auth?.oTPSent?.result?.token);
  const authLoading = useSelector(state => state?.auth?.loading);

  const updateCustomerLoaded = useSelector(state => state.auth.updateCustomerLoaded);
  const loginSuccess = useSelector(state => state.auth.loginSuccess);
  const oTPLoginSuccess = useSelector(state => state.auth.oTPLoginSuccess);
  const { storeCode } = parseQueryParams(['storeCode']);
  const { whatsappId, mobileNo, staffCode } = parseQueryParams([
    'whatsappId',
    'mobileNo',
    'staffCode',
    'storeCode',
  ]);

  const prevUrl = props?.location?.state?.prevUrl;

  useEffect(() => {
    odPushClevertapEvent('Page Viewed', {
      pageName: 'Login Page',
      pageUrl: window.location.href,
    });
  }, []);

  useEffect(() => {
    console.log('this is loginErr ===> loginErr', loginErr);
    dispatch(setToastMessage({ warning: true, message: loginErr, timeout: 3000 }));
  }, [loginErr]);

  useEffect(() => {
    console.log('this is loginErr ===> otpSentError', otpSentError);
    dispatch(setToastMessage({ warning: true, message: otpSentError, timeout: 3000 }));
  }, [otpSentError]);

  useEffect(() => {
    console.log('this is loginErr ===> userInputError', loginErr);
    dispatch(setToastMessage({ warning: true, message: userInputError, timeout: 3000 }));
  }, [userInputError]);

  useEffect(() => {
    let interval;
    if (timerRunning && timer > 0) {
      interval = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      // If timer reaches 0, stop the timer
      setTimerRunning(false);
    }

    // Cleanup function
    return () => clearInterval(interval);
  }, [timer, timerRunning]);

  const handleSubmit = e => {
    e.preventDefault();

    // 1. Basic validation
    const obj = validateLoginCreds(username, password);

    // 2. If creds are valid
    if (obj.isValid) {
      setErrorMsg(obj.msg);

      // Check user account exist or not.
      if (!otpSent) {
        dispatch(
          validateUserInput({
            isMobileUserOnly: obj.isMobileUserOnly,
            validBlock: obj.validBlock,
            username,
            password,
          })
        );
      }

      if (otpSent && isMobileLogin) {
        const data = {
          username,
          code: otpValue,
          phoneCode: '+65',
          type: 'VALIDATE_OTP',
        };
        dispatch(sendValidateOtp(data));
      }
    } else {
      setErrorMsg(obj.msg);
    }
  };

  useEffect(() => {
    console.log('this is user input validate ==>', userInputValidate);
    if (userInputValidate?.result?.accounts?.length > 0) {
      if (isMobileLogin && !otpSent) {
        sendOtp();
      } else {
        dispatch(authenticateUser({ username, password }));
      }
    }
  }, [userInputValidate]);

  useEffect(() => {
    if (otpSentToken) {
      window.location.href = '/';
    }
  }, [otpSentToken]);

  useEffect(() => {
    console.log('@LOGIN LOGIC ====>', isLogin, prevUrl);
  }, [prevUrl]);

  useEffect(() => {
    const { storeCode } = parseQueryParams(['storeCode']);

    const redirectTo = prevUrl ? prevUrl.replace(/\/sg\/en/g, '') : '';

    if (storeCode) {
      if (isLogin && updateCustomerLoaded) {
        if (redirectTo?.length > 0 || redirectTo === '') {
          window.location.href = redirectTo === '/' ? redirectTo : `/sg/en${redirectTo}`;
          // history.push(redirectTo);
        } else {
          history.go(-1);
        }
      }
    } else if (isLogin) {
      if (redirectTo?.length > 0 || redirectTo === '') {
        window.location.href = redirectTo === '/' ? redirectTo : `/sg/en${redirectTo}`;
        // history.push(redirectTo);
      } else {
        history.go(-1);
      }
    }
  }, [history, isLogin, loginSuccess, oTPLoginSuccess, prevUrl, updateCustomerLoaded]);

  useEffect(() => {
    const { mobileNo, staffCode, storeCode } = parseQueryParams([
      'mobileNo',
      'staffCode',
      'storeCode',
    ]);

    if ((loginSuccess || oTPLoginSuccess) && storeCode) {
      dispatch(updateCustomer({ storeCode, staffCode, mobileNo, visitReason }));
    }
  }, [dispatch, loginSuccess, oTPLoginSuccess]);

  useEffect(() => {
    // On each username input validating is it mobile login or email login...
    setOtpSent(false);
    const isSingaporeMobileNumber = validateSingaporeMobileNumber(username);
    const hasEightDigits = /^\d{8}$/.test(username);
    if (isSingaporeMobileNumber && hasEightDigits) {
      setIsMobileLogin(true);
    } else {
      setIsMobileLogin(false);
    }
  }, [username]);

  // This is handling otp errors
  // useEffect(() => {
  //   if (otpSent?.error?.message) {
  //     const message = otpSent?.error?.message?.replaceAll("lenskart", "owndays");
  //     setErrorMsg(message);
  //   }
  // }, [otpSent]);

  const sendOtp = () => {
    const client = apiClient();
    client.setHeader();
    client
      .post(`${apiPath.mobileLoginSendOTP}`, {
        telephone: username,
        captcha: null,
        phoneCode: '+65',
        type: 'SEND_OTP',
      })
      .then(res => {
        console.log('this is send otp response =====> res', res);
        setShowOtpInput(true);
        setOtpSent(true);
        setTimerRunning(true);
      })
      .catch(err => {
        console.log('this is send otp response =====> err', err);
        setShowOtpInput(false);
        setOtpSent(false);
        const msg = err?.message?.replaceAll('lenskart', 'owndays');
        setErrorMsg(msg);
        setTimerRunning(true);
      });
  };

  const handleResendOTP = () => {
    // Simulating OTP generation
    // const newOTP = Math.floor(1000 + Math.random() * 9000);
    // setOTP(newOTP);

    // Reset timer
    setTimer(30);
    setTimerRunning(true);
    sendOtp();
  };

  const handleForgotEmail = e => {
    e.preventDefault();
    dispatch(forgotPasswordAction({ email: forgotEmail }));
  };

  return (
    <div className="od-login-page">
      <div className="od-login-page-left" />
      <div className="od-login-page-right">
        <div className="od-login-page-right-flex">
          {isForgotPassword ? (
            <>
              <h3 className="od-login-page-right-h1">Please enter your email to reset password</h3>
              <div className="od-login-page-right-form">
                <h4>Forgot password?</h4>
                <form id="forgotPasswordDesktop" onSubmit={handleForgotEmail}>
                  <input
                    required
                    placeholder="Please enter your email id"
                    type="email"
                    value={forgotEmail}
                    onChange={e => setForgotEmail(e.target.value)}
                  />
                  {forgotpassError ? (
                    <p className="od-login-page-input-error">
                      {forgotpassError?.message?.replaceAll('phone number or', '')}
                    </p>
                  ) : null}
                  {emailStatus == 'sent' ? (
                    <p className="od-login-page-m-p">
                      We have sent you a mail containing the link to change the password of your
                      account.
                    </p>
                  ) : null}
                  <button className="od-login-page-right-form-sb" type="submit">
                    Continue
                  </button>
                </form>
                {/* <div className="divider-line">
                  <div className="divider-line-left" />
                  <div className="divider-line-text">Or</div>
                  <div className="divider-line-right" />
                </div> */}
                <div className="text-center">
                  Need an account?{' '}
                  <span
                    className="od-login-page-right-form-fp"
                    onClick={() => history.push('/customer/account/signup')}
                  >
                    Sign up
                  </span>
                </div>
              </div>

              {/* {emailStatus == 'fail' ? <p>Something went wrong</p> : null} */}
            </>
          ) : null}

          {!isForgotPassword ? (
            <>
              <h3 className="od-login-page-right-h1">
                Please sign in or create an account to complete checkout
              </h3>
              <div className="od-login-page-right-form">
                <h4>Sign in</h4>
                <form onSubmit={handleSubmit}>
                  <div className="od-login-page-number-wrapper">
                    {isMobileLogin && (
                      <input
                        disabled
                        className="od-login-page-number-wrapper_code"
                        placeholder="+65"
                      />
                    )}
                    <input
                      required
                      className={isMobileLogin ? 'od-login-page-number-wrapper_number' : ''}
                      maxLength={isMobileLogin ? 8 : 100}
                      placeholder="Phone no/email id"
                      type="text"
                      value={username}
                      onChange={e => {
                        const phoneValue = e.target.value;
                        if (isMobileLogin) {
                          setUsername(phoneValue.slice(0, 8));
                        } else {
                          setUsername(e.target.value);
                        }
                      }}
                    />
                  </div>
                  {!isMobileLogin && (
                    <>
                      <input
                        placeholder="Password"
                        type="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                      />

                      {storeCode && (
                        <select
                          id="visitPurpose"
                          name="genvisitPurposeder"
                          value={visitReason}
                          onChange={e => setVisitReason(e.target.value)}
                        >
                          <option value="buyGlasses">Glasses</option>
                          <option value="contactLens">Contact Lens</option>
                          <option value="lensReplacement">Lens Replacement</option>
                          <option value="afterCare">Warranty</option>
                        </select>
                      )}
                    </>
                  )}
                  {!isMobileLogin && (
                    <div
                      className="od-login-page-right-form-fp"
                      onClick={() => setIsForgotPassword(true)}
                    >
                      Forgot password
                    </div>
                  )}
                  {isMobileLogin && showOtpInput && (
                    <>
                      <input
                        className="od-login-otp-input"
                        maxLength="4"
                        placeholder="Enter OTP"
                        type="number"
                        value={otpValue}
                        onChange={e => setOtpValue(e.target.value)}
                      />
                      {storeCode && (
                        <select
                          id="visitPurpose"
                          name="genvisitPurposeder"
                          value={visitReason}
                          onChange={e => setVisitReason(e.target.value)}
                        >
                          <option value="buyGlasses">Glasses</option>
                          <option value="contactLens">Contact Lens</option>
                          <option value="lensReplacement">Lens Replacement</option>
                          <option value="afterCare">Warranty</option>
                        </select>
                      )}
                    </>
                  )}
                  {!timerRunning && isMobileLogin && showOtpInput && (
                    <span className="od-login-page-right-form-fp" onClick={handleResendOTP}>
                      Resend OTP
                    </span>
                  )}
                  {timerRunning && isMobileLogin && showOtpInput && (
                    <span className="od-login-page-right-form-fp">Resend OTP in {timer}</span>
                  )}
                  <button className="od-login-page-right-form-sb" type="submit">
                    {authLoading ? 'Loading...' : 'Login'}
                  </button>
                  {errorMsg && <div className="od-login-page-input-error">{errorMsg}</div>}
                  {userInputValidate?.result?.accounts?.length === 0 && (
                    <div className="od-login-page-input-error">Account does not exists</div>
                  )}
                  {loginError?.message && (
                    <div className="od-login-page-input-error">{loginError?.message}</div>
                  )}
                </form>
                <div className="divider-line">
                  <div className="divider-line-left" />
                  <div className="divider-line-text">Or</div>
                  <div className="divider-line-right" />
                </div>
                <div className="text-center">
                  Need an account?{' '}
                  <span
                    className="od-login-page-right-form-fp"
                    onClick={() => {
                      if (storeCode) {
                        history.push(
                          `/customer/account/signup?whatsappId=${whatsappId}&mobileNo=${mobileNo}&storeCode=${storeCode}`
                        );
                      } else {
                        console.log('@LOGIN LOGIC ====> 2', isLogin, prevUrl);
                        if (prevUrl && prevUrl?.includes('cart')) {
                          return history.push({
                            pathname: '/customer/account/signup',
                            state: { prevUrl },
                          });
                        }

                        history.push('/customer/account/signup');
                      }
                    }}
                  >
                    Sign up
                  </span>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Login;
